/* line 20, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	direction: ltr;
}

/* line 33, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .vjs-aspect-ratio--16-9 {
	padding-top: 56.25%;
}

/* line 39, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .video-js {
	overflow: hidden;
}

/* line 43, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .video-js.videojs-dimensions {
	padding: 0;
}

/* line 48, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .video-js,
.c-video-container .video-js .vjs-tech, .c-video-container .video-js.video-js.vjs-fluid {
	width: 100%;
	height: 100%;
	min-width: 0;
	min-height: 0;
	max-width: 100%;
	max-height: 100%;
}

/* line 62, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .video-js .vjs-tech {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* line 68, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .vjs-poster {
	z-index: 0;
	overflow: hidden;
}

/* line 73, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .vjs-poster.vjs-poster--custom {
	background-color: black;
}

/* line 79, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .vjs-playing .vjs-poster {
	display: none;
}

/* line 83, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .vjs-paused:not(.vjs-has-started) .vjs-poster {
	display: block;
	width: 100%;
	height: 100%;
}

/* line 94, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.c-video-container--always-fill .video-js,
.c-video-container.c-video-container--always-fill .video-js.vjs-fluid,
.c-video-container.c-video-container--always-fill .video-js .vjs-tech {
	min-width: 100%;
	min-height: 100%;
	max-width: none;
	max-height: none;
}

/* line 105, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.c-video-container--always-fill .video-js:not(.has-controls) .vjs-tech {
	width: auto;
	height: auto;
}

/* line 110, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.c-video-container--always-fill .video-js.has-controls .vjs-tech {
	width: auto;
	height: 100%;
}

/* line 119, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.c-video-container--always-fill .video-js .vjs-tech.video-ratio--portrait {
	height: 0;
}

/* line 124, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.c-video-container--always-fill .video-js .vjs-tech.video-ratio--landscape {
	width: 0;
}

/* line 131, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.c-video-container--always-fill .vjs-poster {
	background-size: cover;
	background-origin: border-box;
}

/* line 140, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.videoplayer--is-ios.videoplayer--tech-youtube .vjs-poster,
.c-video-container.videoplayer--is-ios.videoplayer--tech-youtube .videoplayer__controls, .c-video-container.videoplayer--is-ios.videoplayer--tech-vimeo .vjs-poster,
.c-video-container.videoplayer--is-ios.videoplayer--tech-vimeo .videoplayer__controls {
	pointer-events: none;
}

/* line 147, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .vjs-controls-disabled video::-webkit-media-controls-start-playback-button {
	appearance: none;
	display: none !important;
}

/* line 154, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container.videoplayer--no-sources .vjs-loading-spinner,
.c-video-container.videoplayer--no-sources .videoplayer__controls .videoplayer__controls__button {
	display: none;
}

/* line 163, scss/80-themes/Saunders/60-components/videoplayer.scss */
.videoplayer__controls {
	font-size: 14px;
	font-size: 1rem;
	left: 0;
	position: absolute;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .videoplayer__controls {
	left: auto;
	right: 0;
}

/* line 169, scss/80-themes/Saunders/60-components/videoplayer.scss */
.video-js:not(.has-controls) .videoplayer__controls {
	top: 0;
	width: 100%;
	height: 100%;
}

/* line 175, scss/80-themes/Saunders/60-components/videoplayer.scss */
.has-controls .videoplayer__controls {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	height: auto;
}

/* line 182, scss/80-themes/Saunders/60-components/videoplayer.scss */
.videoplayer__controls__button {
	font-size: 1em;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	position: absolute;
	transition: all .5s linear;
	background-color: rgba(54, 54, 54, 0.3);
	padding: 10px;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
}

@media only screen and (max-width: 47.999em) {
	/* line 182, scss/80-themes/Saunders/60-components/videoplayer.scss */
	.videoplayer__controls__button {
		font-size: 1em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 182, scss/80-themes/Saunders/60-components/videoplayer.scss */
	.videoplayer__controls__button {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 182, scss/80-themes/Saunders/60-components/videoplayer.scss */
	.videoplayer__controls__button {
		font-size: 1em;
	}
}

/* line 194, scss/80-themes/Saunders/60-components/videoplayer.scss */
.videoplayer__controls__button .icon {
	font-size: 5em;
	color: white;
}

/* line 199, scss/80-themes/Saunders/60-components/videoplayer.scss */
.videoplayer__controls__button.unselectable {
	opacity: 0 !important;
}

/* line 203, scss/80-themes/Saunders/60-components/videoplayer.scss */
.videoplayer__controls__button--blink {
	animation: .8s button-blink forwards;
}

/* line 207, scss/80-themes/Saunders/60-components/videoplayer.scss */
.c-video-container .video-js:not(.vjs-playing) .videoplayer__controls__button--play,
.c-video-container:hover .vjs-playing .videoplayer__controls__button--pause {
	opacity: 1;
	visibility: visible;
}

/* line 213, scss/80-themes/Saunders/60-components/videoplayer.scss */
.vjs-has-started.has-controls .videoplayer__controls__button {
	display: none;
}

@keyframes button-blink {
	from {
		transform: translate(-50%, -50%) scale(1, 1);
		opacity: 0;
		visibility: visible;
	}
	70% {
		transform: translate(-50%, -50%) scale(1.2, 1.2);
		opacity: 1;
	}
	99% {
		transform: translate(-50%, -50%) scale(1, 1);
		opacity: 0;
		visibility: visible;
	}
	to {
		visibility: hidden;
	}
}

/* line 247, scss/80-themes/Saunders/60-components/videoplayer.scss */
.vjs-youtube .vjs-iframe-blocker {
	display: none;
}

/* line 251, scss/80-themes/Saunders/60-components/videoplayer.scss */
.vjs-youtube.vjs-user-inactive .vjs-iframe-blocker {
	display: block;
}

/* line 256, scss/80-themes/Saunders/60-components/videoplayer.scss */
.controls__video-overlay {
	z-index: 999;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

/* line 265, scss/80-themes/Saunders/60-components/videoplayer.scss */
.carousel--is-ios .has-youtube-video .controls__video-overlay {
	pointer-events: none;
}

/* line 270, scss/80-themes/Saunders/60-components/videoplayer.scss */
.controls__video-play {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	color: white;
	font-size: 5em;
}

/* line 276, scss/80-themes/Saunders/60-components/videoplayer.scss */
.controls__video-overlay + .controls__video-play {
	z-index: 999;
}

/* line 280, scss/80-themes/Saunders/60-components/videoplayer.scss */
.carousel--is-ios .has-youtube-video .controls__video-play {
	pointer-events: none;
}

/* line 283, scss/80-themes/Saunders/60-components/videoplayer.scss */
.carousel--is-ios .has-youtube-video .controls__video-play.icon-pause {
	visibility: hidden;
	opacity: 0;
}

/* line 290, scss/80-themes/Saunders/60-components/videoplayer.scss */
.video-embed-container iframe {
	width: 100%;
	height: 100%;
}

/* line 295, scss/80-themes/Saunders/60-components/videoplayer.scss */
.vjs-text-track-display {
	pointer-events: none;
}

/*# sourceMappingURL=../../../../true */